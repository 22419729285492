import React from "react";
import styled from "styled-components";

const BeatingHeart = styled.div`
    margin: auto;
    top: 150px;
    visibility: ${props => props.show ? 'visible' :'hidden'};
    z-index: 1;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg);
    transform-origin: 32px 32px;

    div {
        top: 0;
        left: 0
        position: absolute;
        width: 35px;
        height: 35px;
        background: var(--green);
        animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    div:after,
    div:before {
        content: " ";
        position: absolute;
        display: block;
        width: 35px;
        height: 35px;
        background: var(--green);
    }
    div:before {
        left: -17px;
        border-radius: 50% 0 0 50%;
    }
    div:after {
        top: -17px;
        border-radius: 50% 50% 0 0;
    }
    @keyframes lds-heart {
        0% {
        transform: scale(0.95);
        }
        5% {
        transform: scale(1.1);
        }
        39% {
        transform: scale(0.85);
        }
        45% {
        transform: scale(1);
        }
        60% {
        transform: scale(0.95);
        }
        100% {
        transform: scale(0.9);
        }
    }
  `;

class WeekData extends React.Component {
    
    render() {
        return(
            <BeatingHeart show={this.props.show}> 
                <div></div>
            </BeatingHeart>
        )
    }
}

export default WeekData;