import React from "react";
import styled from "styled-components";

import fetch from "./shared/Fetch";

const Container = styled.div`
  margin: auto;
  width: 40%;
  min-height: 40%;
  background: var(--lightPink);
  padding: 2rem;
`;

const LoginButton = styled.button`
  margin-top: 10px;
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
  font-size: 2rem;
`;

function getQueryVariable(str, variable) {
  var query = str.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
}

function getToken(str) {
  return getQueryVariable(str, "id_token");
}

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if (window.location.hash) {
      const token = getToken(window.location.hash);
      this.verifyToken(token);
    }
  }

  verifyToken(token) {
    fetch("/login?_token=" + token).then(response => {
      if (!response.token) {
        return;
      } else {
        this.props.onLoggedIn(response.token);
      }
    });
  }

  onChange(field) {
    return e =>
      this.setState({
        [field]: e.currentTarget.value
      });
  }

  login(e) {
    e.preventDefault();
    fetch("/login", { mode: "cors" })
      .then(response => {
        if (response.location) {
          window.location.replace(response.location);
        } else if (response.token) {
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <Container>
        <form>
          <h1>Logg inn</h1>
          <p>
            Du må være logget inn for å få tilgang til denne tjenesten. Logg inn via SSO ved å
            klikke på knappen under
          </p>

          <LoginButton type="submit" onClick={this.login}>
            Autoriser applikasjon
          </LoginButton>
        </form>
      </Container>
    );
  }
}
