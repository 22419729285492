import React from "react";
import fetch from "./shared/Fetch";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { getMomentDate, formatDate } from "./shared/TimeUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import { Heading } from "./sharedComponents/Atoms";

import "react-datepicker/dist/react-datepicker.css";

const ConsultantTable = styled.table`
  width: 100%;
`;

const LeadingHeading = styled.th`
  text-align: left;
`;

const ConsultantRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const ConsultantData = styled.td`
  padding: 0.5rem;
  text-align: center;
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
`;

const HiddenSelect = styled.select`
  background: transparent;
  border: 0px;
  outline: none;
  font-size: 16px;
  color: var(--black);
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

const ConsultantNameInput = styled.input`
  font-size: 1rem
  border: 0;
  outline: none;
  font-family: sans-serif;
  color: var(--black);
  background: transparent;
  text-align: left;
`;

const NewConsultantButton = styled.button`
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
`;

class AdministerConsultants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      departments: [],
      department: this.props.match.params.department,
      startDate: getMomentDate(),
      showNewConsultantRow: false,
      waitingForData: true,
      newConsultant: {
        name: "",
        departmentId: this.props.match.params.department,
        endDate: null
      }
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNewConsultantDateChange = this.handleNewConsultantDateChange.bind(this);
    this.handleNewConsultantInputChange = this.handleNewConsultantInputChange.bind(this);
    this.toggleNewConsultant = this.toggleNewConsultant.bind(this);
  }

  componentDidMount() {
    this.fetchConsultants();
    this.fetchDepartments();
  }

  handleInputChange(index, e) {
    const { consultants } = this.state;
    const property = e.target.name;

    const newConsultants = consultants.map(function(val, i) {
      if (i !== index) return val;
      return {
        ...val,
        [property]: e.target.type === "select-one" ? e.target.value.toLowerCase() : e.target.value
      };
    });

    if (e.target.type === "select-one") {
      this.setState({ consultants: newConsultants }, () =>
        this.saveConsultant(newConsultants[index])
      );
    } else {
      this.setState({ consultants: newConsultants });
    }
  }

  handleNewConsultantInputChange(e) {
    const { newConsultant } = this.state;
    const property = e.target.name;
    newConsultant[property] =
      e.target.type === "select-one" ? e.target.value.toLowerCase() : e.target.value;
    this.setState({ newConsultant: newConsultant });
  }

  handleNewConsultantDateChange(date) {
    const { newConsultant } = this.state;
    newConsultant.endDate = date ? formatDate(date, "YYYYMMDD") : null;
    this.setState({ newConsultant: newConsultant });
  }

  handleDateChange(date, index) {
    const { consultants } = this.state;
    consultants[index].endDate = date ? formatDate(date, "YYYYMMDD") : null;
    this.setState({ consultants: consultants });
  }

  handleSaveConsultant(index) {
    const { consultants } = this.state;
    this.saveConsultant(consultants[index]);
  }

  toggleNewConsultant(e) {
    e.preventDefault();
    this.setState({ showNewConsultantRow: !this.state.showNewConsultantRow });
  }

  uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { consultants, departments, newConsultant } = this.state;
    return (
      <div className="module">
        <AppHeading
          department={this.props.match.params.department}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
        >
          {" "}
        </AppHeading>

        <form className="administer-consultants content">
          <Heading>Administrer konsulenter</Heading>
          <Spinner show={this.state.waitingForData} />
          <ConsultantTable>
            <thead>
              <tr>
                <LeadingHeading>Navn</LeadingHeading>
                <th>Avdeling</th>
                <th>Sluttdato</th>
              </tr>
            </thead>
            <tbody>
              {consultants.map((consultant, i) => (
                <ConsultantRow key={consultant.consultantId}>
                  <ConsultantName>
                    <ConsultantNameInput
                      value={consultant.name}
                      name="name"
                      onChange={this.handleInputChange.bind(this, i)}
                      onBlur={this.handleSaveConsultant.bind(this, i)}
                    />
                  </ConsultantName>
                  <ConsultantData>
                    <HiddenSelect
                      name="departmentId"
                      onChange={this.handleInputChange.bind(this, i)}
                      value={this.uppercaseFirstLetter(consultant.departmentId)}
                    >
                      {departments.map(department => (
                        <option key={department.name}>{department.name}</option>
                      ))}
                    </HiddenSelect>
                  </ConsultantData>
                  <ConsultantData>
                    <DatePicker
                      className="datepicker-input"
                      selected={
                        consultant.endDate && consultant.endDate !== ""
                          ? getMomentDate(consultant.endDate, "YYYYMMDD")
                          : null
                      }
                      onChange={date => this.handleDateChange(date, i)}
                      onBlur={this.handleSaveConsultant.bind(this, i)}
                      placeholderText="Klikk og velg en dato"
                    />
                  </ConsultantData>
                </ConsultantRow>
              ))}
              {this.state.showNewConsultantRow ? (
                <ConsultantRow key="new-consultant">
                  <ConsultantName>
                    <ConsultantNameInput
                      placeholder="Navn"
                      value={newConsultant.name}
                      name="name"
                      onChange={this.handleNewConsultantInputChange}
                    />
                  </ConsultantName>
                  <ConsultantData>
                    <HiddenSelect
                      name="departmentId"
                      onChange={this.handleNewConsultantInputChange}
                      value={this.uppercaseFirstLetter(newConsultant.departmentId)}
                    >
                      {departments.map(department => (
                        <option key={department.name}>{department.name}</option>
                      ))}
                    </HiddenSelect>
                  </ConsultantData>
                  <ConsultantData>
                    <DatePicker
                      className="datepicker-input"
                      selected={
                        newConsultant.endDate && newConsultant.endDate !== ""
                          ? getMomentDate(newConsultant.endDate, "YYYYMMDD")
                          : null
                      }
                      onChange={date => this.handleNewConsultantDateChange(date)}
                      placeholderText="Klikk og velg en dato"
                    />
                  </ConsultantData>
                </ConsultantRow>
              ) : null}
            </tbody>
          </ConsultantTable>
          <NewConsultantButton
            style={{
              display: !this.state.showNewConsultantRow ? "inline-block" : "none"
            }}
            onClick={this.toggleNewConsultant}
            type="button"
          >
            Legg til konsulent
          </NewConsultantButton>
          <NewConsultantButton
            style={{
              display: this.state.showNewConsultantRow ? "inline-block" : "none"
            }}
            onClick={e => {
              e.preventDefault();
              this.saveConsultant(newConsultant);
            }}
            type="submit"
          >
            Lagre
          </NewConsultantButton>
          <NewConsultantButton
            style={{
              display: this.state.showNewConsultantRow ? "inline-block" : "none",
              marginLeft: "10px"
            }}
            onClick={this.toggleNewConsultant}
            type="button"
          >
            Avbryt
          </NewConsultantButton>
        </form>
      </div>
    );
  }

  saveConsultant(consultant) {
    const newConsultant = {
      consultantId: consultant.consultantId,
      name: consultant.name,
      departmentId: consultant.departmentId,
      endDate: consultant.endDate
    };

    fetch("/consultants", {
      method: consultant.consultantId ? "PUT" : "POST",
      body: JSON.stringify(newConsultant)
    }).then(
      response => {
        if (response.status) {
          this.setState({
            newEngagementId: -1,
            showNewConsultantRow: false
          });
        } else {
          this.setState({
            newEngagementId: -1,
            showNewConsultantRow: false,
            consultants: this.state.consultants.concat({
              ...newConsultant,
              consultantId: response.generatedId
            })
          });
        }
      },
      reason => {
        console.log("Something went wrong while performing the request", reason);
      }
    );
  }

  fetchConsultants() {
    this.setState({ waitingForData: true });
    fetch("/consultants?department=" + this.state.department)
      .catch(reason => {
        console.log("Something went wrong while fetching Consultants");
      })
      .then(response => {
        this.setState({ consultants: response, waitingForData: false });
      });
  }

  fetchDepartments() {
    fetch("/departments")
      .catch(reason => console.log("Something went wrong while fetching departments"))
      .then(response => {
        this.setState({ departments: response });
      });
  }
}

export default AdministerConsultants;
