import moment from "moment";
import "moment/locale/nb";

const getCurrentWeek = () => ("" + moment().isoWeek()).padStart(2, "0");

const getCurrentYearWeek = () => new Date().getUTCFullYear() + getCurrentWeek();

const getOffsetYearWeek = offset => {
  let date = moment().add(offset, 'weeks');
  let offsetYear = date.toDate().getUTCFullYear();
  let offsetYearWeek = offsetYear +  ("" + date.isoWeek()).padStart(2, "0")  ;
  return offsetYearWeek;
} 

const getCurrentYear = () => new Date().getFullYear();

const getMomentDate = (date, pattern) =>
  date ? moment(date, pattern) : moment();

const formatDate = (date, pattern) => moment(date).format(pattern);

const splitYearWeek = yearWeek => {
  return {
    year: yearWeek.substr(0, 4),
    week: yearWeek.substr(4, 2)
  };
};

const getFirstDayOfWeek = yearWeek => {
  const yearAndWeek = splitYearWeek(yearWeek);

  moment.locale('nb');
  let ret = moment()
    .year(yearAndWeek.year)
    .week(yearAndWeek.week)
    .weekday(0)
    .format("DD.MM");
    return ret;
};

const getNextMonthsBasedOnYearMonth = (yearMonth, numberOfMonths) => {
  let monthNumber = String(yearMonth).substr(4, 2);
  moment.locale("nb");
  const months = [];
  for (var i = 0; i < numberOfMonths; i++) {
    if (monthNumber > 12) monthNumber = 1;
    let month = moment(
      monthNumber.length === 1 ? "0" + monthNumber : monthNumber,
      "MM"
    ).format("MMMM");
    months.push(month.charAt(0).toUpperCase() + month.slice(1));
    monthNumber++;
  }
  return months;
};

export {
  getCurrentWeek,
  getCurrentYearWeek,
  getOffsetYearWeek,
  getCurrentYear,
  getMomentDate,
  formatDate,
  splitYearWeek,
  getFirstDayOfWeek,
  getNextMonthsBasedOnYearMonth
};
