import React from "react";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import { Link } from "react-router-dom";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";

const CustomerName = styled.input`
  background: inherit;
  border: 0;
  font-size: 1.5rem;
  width: 100%;
  outline: none;
`;

const EngagementTable = styled.table`
  margin-top: 2rem;
  width: 100%;
`;

const EngagementNameColumn = styled.td`
  padding: 0.5rem;
  width: 50%;
`;

const EngagementMetaDataInput = styled.input`
  font-size: 1rem;
  border: 0;
  outline: none;
  font-family: sans-serif;
  color: var(--black);
  background: transparent;
  text-align: center;

  @media (max-width: 750px) {
    width: 100%;
    font-size: 0.7rem;
  }
`;

const EngagementMetaDataColumn = styled.td`
  text-align: center;
`;

const StyledEngagementRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const LeadingHeading = styled.th`
  text-align: left;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const TableHeading = styled.th`
  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const HiddenSelect = styled.select`
  background: transparent;
  border: 0px;
  outline: none;
  font-size: 1rem;
  color: var(--black);
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const NewProjectButton = styled.button`
  margin-top: 10px;
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
`;

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: { customerName: "", engagements: [] },
      valueChanged: false,
      showNewEngagementRow: false,
      waitingForData: true,
      newEngagement: {
        engagementName: "",
        status: "Tilbud",
        partner: "",
        projectManager: ""
      },
      department: this.props.match.params.department
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.toggleNewEngagement = this.toggleNewEngagement.bind(this);
    this.saveNewEngagement = this.saveNewEngagement.bind(this);
  }

  componentDidMount() {
    this.fetchCustomer();
  }

  handleInputChange(e) {
    const customer = this.state.customer;
    const property = e.target.name;
    customer[property] = e.target.value;
    this.setState({ customer: customer, valueChanged: true });
  }

  toggleNewEngagement() {
    this.setState({ showNewEngagementRow: !this.state.showNewEngagementRow });
  }

  render() {
    const { customer, department } = this.state;
    let weeks = this.props.match.params.weeks;
    let offset = this.props.match.params.offset;
    return (
      <div className="module">
        <AppHeading department={department} offset={offset} weeks={weeks} />
        <div className="customer content">
          <CustomerName
            onChange={this.handleInputChange}
            onBlur={this.saveCustomer}
            name="customerName"
            value={customer.customerName}
          />
          <form className="customer__container">
            <Spinner show={this.state.waitingForData} />
            <EngagementTable>
              <thead>
                <tr>
                  <LeadingHeading>Engasjement</LeadingHeading>
                  <TableHeading>Status</TableHeading>
                  <TableHeading>Ansvarlig partner</TableHeading>
                  <TableHeading>Prosjektleder</TableHeading>
                </tr>
              </thead>
              <tbody>
                {customer.engagements.map(engagement => (
                  <EngagementRow
                    key={engagement.engagementId}
                    engagement={engagement}
                    department={department}
                    weeks={weeks}
                    offset={offset}
                  />
                ))}
                {!this.state.showNewEngagementRow ? null : (
                  <EngagementRow
                    engagement={this.state.newEngagement}
                    saveEngagementToParentState={engagement =>
                      this.setState({ newEngagement: engagement })
                    }
                    isNewEngagement={true}
                  />
                )}
              </tbody>
            </EngagementTable>
            <NewProjectButton
              style={{
                display: !this.state.showNewEngagementRow ? "inline-block" : "none",
                margin: "10px 0 0 0"
              }}
              type="button"
              onClick={this.toggleNewEngagement}
            >
              Legg til nytt prosjekt
            </NewProjectButton>
            <NewProjectButton
              style={{
                display: this.state.showNewEngagementRow ? "inline-block" : "none"
              }}
              type="submit"
              onClick={this.saveNewEngagement}
            >
              Lagre
            </NewProjectButton>
            <NewProjectButton
              style={{
                display: this.state.showNewEngagementRow ? "inline-block" : "none",
                margin: "10px 0 0 10px"
              }}
              type="button"
              onClick={this.toggleNewEngagement}
            >
              Avbryt
            </NewProjectButton>
          </form>
        </div>
      </div>
    );
  }

  saveCustomer() {
    if (this.state.valueChanged) {
      fetch("/customers/" + this.state.customer.customerId, {
        method: "PUT",
        body: JSON.stringify({
          name: this.state.customer.customerName,
          departmentId: this.state.department
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    }
  }

  saveNewEngagement(e) {
    e.preventDefault();
    const { newEngagement } = this.state;
    fetch("/engagements", {
      method: "POST",
      body: JSON.stringify({
        customerId: this.state.customer.customerId,
        name: newEngagement.engagementName,
        status: newEngagement.status,
        partner: newEngagement.partner,
        projectManager: newEngagement.projectManager
      })
    }).then(
      response => {
        this.setState(
          {
            showNewEngagementRow: false,
            newEngagement: {
              engagementName: "",
              status: "Tilbud",
              partner: "",
              projectManager: ""
            }
          },
          this.fetchCustomer
        );
      },
      reason => console.log("Something went wrong during the request", reason)
    );
  }

  fetchCustomer() {
    this.setState({ waitingForData: true });
    fetch("/customers/" + this.props.match.params.id)
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        this.setState({ customer: response, waitingForData: false });
      });
  }
}

class EngagementRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      engagement: this.props.engagement,
      valueChange: false,
      isNewEngagement: this.props.isNewEngagement ? true : false,
      department: this.props.department,
      weeks: this.props.weeks,
      offset: this.props.offset
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveEngagement = this.saveEngagement.bind(this);
  }

  handleInputChange(e) {
    const engagement = this.state.engagement;
    const property = e.target.name;
    engagement[property] = e.target.value;
    if (e.target.type === "select-one") {
      this.setState({ engagement: engagement, valueChanged: true }, () => {
        this.saveEngagement();
      });
    } else {
      this.setState({ engagement: engagement, valueChanged: true });
    }
  }

  render() {
    const { engagement, department, weeks, offset } = this.state;
    return (
      <StyledEngagementRow>
        <EngagementNameColumn>
          {!this.state.isNewEngagement ? (
            <Link
              to={
                "/" +
                department +
                "/project/" +
                engagement.engagementId +
                "/" +
                weeks +
                "/" +
                offset
              }
            >
              {engagement.engagementName}
            </Link>
          ) : (
            <EngagementMetaDataInput
              style={{ textAlign: "left" }}
              placeholder="Prosjektnavn"
              onChange={this.handleInputChange}
              onBlur={this.saveEngagement}
              name="engagementName"
              value={engagement.engagmentName}
            />
          )}
        </EngagementNameColumn>
        <EngagementMetaDataColumn>
          <HiddenSelect value={engagement.status} onChange={this.handleInputChange} name="status">
            <option>Tilbud</option>
            <option>Tapt</option>
            <option defaultValue>Ordre</option>
            <option>Avsluttet</option>
            <option>Internt</option>
          </HiddenSelect>
        </EngagementMetaDataColumn>
        <EngagementMetaDataColumn>
          <EngagementMetaDataInput
            placeholder="Ansvarlig partner"
            onChange={this.handleInputChange}
            onBlur={this.saveEngagement}
            name="partner"
            value={engagement.partner}
          />
        </EngagementMetaDataColumn>
        <EngagementMetaDataColumn>
          <EngagementMetaDataInput
            placeholder="Prosjektleder"
            onChange={this.handleInputChange}
            onBlur={this.saveEngagement}
            name="projectManager"
            value={engagement.projectManager}
          />
        </EngagementMetaDataColumn>
      </StyledEngagementRow>
    );
  }

  saveEngagement() {
    if (!this.state.isNewEngagement && this.state.valueChanged) {
      fetch("/engagements/" + this.state.engagement.engagementId, {
        method: "PUT",
        body: JSON.stringify({
          name: this.state.engagement.engagementName,
          status: this.state.engagement.status,
          partner: this.state.engagement.partner,
          projectManager: this.state.engagement.projectManager
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    } else if (this.state.isNewEngagement) {
      this.props.saveEngagementToParentState(this.state.engagement);
    }
  }
}

export default Customer;
