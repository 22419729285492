import React from "react";
import { Link } from "react-router-dom";

class AppHeading extends React.Component {
  render() {
    let department = this.props.department;
    let weeks = this.props.weeks;
    let offset = this.props.offset;
    let hideItems = this.props.hideItems;

    return (
      <header className="hero">
        <h1 className="hero__title">
          <Link className="hero__titleLink" to={"/" + department + "/consultants/8/0"}>
            Bemanningssystem
          </Link>
        </h1>
        {!hideItems ? (
          <nav className="hero__menu">
            <ul className="hero__menuItems">
              <li>
                <Link
                  className="hero__menuItem"
                  to={"/" + department + "/consultants/" + weeks + "/" + offset}
                >
                  Konsulenter
                </Link>
              </li>
              <li>
                <Link
                  className="hero__menuItem"
                  to={"/" + department + "/customers/" + weeks + "/" + offset}
                >
                  Kunder
                </Link>
              </li>
              <li>
                <Link
                  className="hero__menuItem"
                  to={"/" + department + "/report/" + weeks + "/" + offset}
                >
                  Rapporter
                </Link>
              </li>
              <li>
                <Link
                  className="hero__menuItem"
                  to={"/" + department + "/budget/" + weeks + "/" + offset}
                >
                  Budsjett
                </Link>
              </li>
              <li>
                <Link
                  className="hero__menuItem"
                  to={"/" + department + "/administer/" + weeks + "/" + offset}
                >
                  Administrasjon
                </Link>
              </li>
              <li>
                <Link className="hero__menuItem" to={"/"}>
                  Velg avdeling
                </Link>
              </li>
            </ul>
          </nav>
        ) : null}
      </header>
    );
  }
}

export default AppHeading;
