import React from "react";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import { getNextMonthsBasedOnYearMonth } from "./shared/TimeUtil";
import { Bar } from "react-chartjs-2";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";

const Heading = styled.h3`
  padding: 0 0 1rem 0;
`;

const ReportTable = styled.table`
  font-size: 0.8em;
  width: 100%;
  border-collapse: collapse;
`;

const StyledReportRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
`;

const MonthData = styled.td`
  white-space: nowrap;
  text-align: center;
  padding: 0.5rem;
`;

const OfferData = styled.span`
  font-size: 0.6rem;
  color: var(--purple);
`;

const StyledNameColumn = styled.td`
  text-align: left;
  padding: 0.5rem;
`;

const LeadingHeading = styled.th`
  text-align: left;
`;

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: this.props.match.params.department,
      report: { consultants: [], budget: [] },
      reportOrderedSum: [],
      reportOfferedSum: [],
      reportOfferedAndOrderedSum: [],
      reportOfferedAndOrderedBudgetSum: [],
      reportOrderedBudgetSum: [],
      waitingForData: true,
      budget: [],
      months: []
    };
  }

  componentDidMount() {
    this.fetchReport();
  }

  render() {
    const {
      report,
      reportOrderedSum,
      reportOfferedSum,
      reportOfferedAndOrderedSum,
      reportOfferedAndOrderedBudgetSum,
      reportOrderedBudgetSum,
      months,
      budget
    } = this.state;
    return (
      <div className="module">
        <AppHeading
          department={this.props.match.params.department}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
        >
          {" "}
        </AppHeading>
        <div className="report content">
          <Heading>Rapport</Heading>
          <Spinner show={this.state.waitingForData} />
          <ReportGraph
            months={months}
            budget={budget}
            offered={reportOfferedSum}
            ordered={reportOrderedSum}
          />
          <ReportTable style={{ marginTop: "30px" }}>
            <thead>
              <tr>
                <LeadingHeading>Konsulent</LeadingHeading>
                {months.map((month, i) => (
                  <th key={i}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {report.consultants.map((consultant, i) => (
                <StyledReportRow key={i}>
                  <StyledNameColumn>{consultant.name}</StyledNameColumn>
                  {consultant.staffing.map((month, j) => (
                    <MonthData key={j}>
                      {month.orderedHours} <OfferData>{month.offeredHours}</OfferData>{" "}
                    </MonthData>
                  ))}
                </StyledReportRow>
              ))}

              <SumRow style={{ borderTop: "1px solid var(--black)" }}>
                <StyledNameColumn>Sum ordrereserve</StyledNameColumn>
                {reportOrderedSum.map((sum, i) => (
                  <MonthData key={i}>{sum}</MonthData>
                ))}
              </SumRow>
              <SumRow>
                <StyledNameColumn>Ordrereserve mot budsjett</StyledNameColumn>
                {reportOrderedBudgetSum.map((sum, i) => (
                  <MonthData key={i}>{sum}%</MonthData>
                ))}
              </SumRow>
              <SumRow>
                <StyledNameColumn>Sum tilbudsreserve</StyledNameColumn>
                {reportOfferedAndOrderedSum.map((sum, i) => (
                  <MonthData key={i}>{sum}</MonthData>
                ))}
              </SumRow>
              <SumRow>
                <StyledNameColumn>Tilbudsreserve mot budsjett</StyledNameColumn>
                {reportOfferedAndOrderedBudgetSum.map((sum, i) => (
                  <MonthData key={i}>{sum}%</MonthData>
                ))}
              </SumRow>
            </tbody>
          </ReportTable>
        </div>
      </div>
    );
  }

  getReportBudgetSum(budget, reportSum) {
    return reportSum.map((sum, i) => {
      return (
        ((sum === 0 ? 1 : sum) / (budget[i].value === 0 ? 1 : budget[i].value)) *
        100
      ).toFixed(0);
    });
  }

  getReportSum(consultants, properties) {
    return consultants
      .map(consultant => consultant.staffing)
      .reduce((result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          for (var j = 0; j < properties.length; j++) {
            result[i] += parseInt(currentValue[i][properties[j]], 10);
          }
        }
        return result;
      }, Array(12).fill(0));
  }

  fetchReport() {
    this.setState({ waitingForData: true });
    fetch("/reports/forecast?department=" + this.state.department)
      .then(response => {
        const reportOfferedAndOrderedSum = this.getReportSum(response.consultants, [
          "offeredHours",
          "orderedHours"
        ]);
        const reportOrderedSum = this.getReportSum(response.consultants, ["orderedHours"]);
        const reportOfferedSum = this.getReportSum(response.consultants, ["offeredHours"]);

        const reportOfferedAndOrderedBudgetSum = this.getReportBudgetSum(
          response.budget,
          reportOfferedAndOrderedSum
        );

        const reportOrderedBudgetSum = this.getReportBudgetSum(response.budget, reportOrderedSum);

        const months = getNextMonthsBasedOnYearMonth(response.budget[0].yearMonth, 12);
        this.setState({
          months: months,
          report: response,
          budget: response.budget.map(budget => budget.value),
          reportOfferedAndOrderedSum: reportOfferedAndOrderedSum,
          reportOfferedSum: reportOfferedSum,
          reportOrderedSum: reportOrderedSum,
          reportOfferedAndOrderedBudgetSum: reportOfferedAndOrderedBudgetSum,
          reportOrderedBudgetSum: reportOrderedBudgetSum,
          waitingForData: false
        });
      })
      .catch(reason => console.log("Something went wrong while fetching report"));
  }
}

class ReportGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      months: this.props.months,
      budget: this.props.budget,
      ordered: this.props.ordered,
      offered: this.props.offered
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      months: nextProps.months,
      budget: nextProps.budget,
      ordered: nextProps.ordered,
      offered: nextProps.offered
    });
  }

  render() {
    const { months, budget, ordered, offered } = this.state;
    const budgetColor = "#ff0166",
      orderedColor = "#03dac6",
      offeredColor = "#6200ee";
    const options = {
      responsive: true,
      tooltips: {
        mode: "label"
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            },
            labels: months,
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true,
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              beginAtZero: false
            }
          }
        ]
      },
      animation: {
        duration: 0
      }
    };

    const data = {
      datasets: [
        {
          type: "line",
          label: "Budsjett",
          data: budget,
          fill: false,
          borderColor: budgetColor,
          backgroundColor: budgetColor,
          pointBorderColor: budgetColor,
          pointBackgroundColor: budgetColor,
          pointHoverBackgroundColor: budgetColor,
          pointHoverBorderColor: budgetColor,
          yAxisID: "y-axis-1"
        },
        {
          type: "bar",
          label: "Ordre",
          data: ordered,
          fill: false,
          backgroundColor: orderedColor,
          borderColor: orderedColor,
          hoverBackgroundColor: orderedColor,
          hoverBorderColor: orderedColor,
          yAxisID: "y-axis-1"
        },
        {
          type: "bar",
          label: "Tilbud",
          data: offered,
          fill: false,
          backgroundColor: offeredColor,
          borderColor: offeredColor,
          hoverBackgroundColor: offeredColor,
          hoverBorderColor: offeredColor,
          yAxisID: "y-axis-1"
        }
      ]
    };
    return <Bar data={data} options={options} />;
  }
}

export default Report;
