import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TableHeading from "./TableHeading";

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const WeeksOptions = styled.div`
  cursor: pointer;
  border-bottom: none;
  margin-bottom: 2rem;
  margin-left: auto;

  @media (max-width: 900px) {
    visibility: hidden;
  }
`;

const WeekLink = styled(Link)`
  margin: 0.3rem;
`;


function getNowPathPath() {
  let pathname = window.location.pathname;
  let params = pathname.split("/");
  params[params.length - 1] = "0";
  return params.join("/");
}


function getWeekPathPath(weeks) {
  let pathname = window.location.pathname;
  let params = pathname.split("/");
  params[params.length - 2] = weeks;
  return params.join("/");
}

export default function Table({ leadingHeaders, yearWeeks, children }) {
  return (
    <TableContainer>
      <WeeksOptions>
        <WeekLink to={getNowPathPath()}>Nå</WeekLink>
        <WeekLink to={getWeekPathPath(8)}>8 uker</WeekLink>
        <WeekLink to={getWeekPathPath(12)}>12 uker</WeekLink>
        <WeekLink to={getWeekPathPath(26)}>26 uker</WeekLink>
      </WeeksOptions>

      <StyledTable>
        <TableHeading leadingHeaders={leadingHeaders} yearWeeks={yearWeeks} />
        <tbody>{children}</tbody>
      </StyledTable>
    </TableContainer>
  );
}
