import React from "react";
import { Link } from "react-router-dom";
import fetch from "./shared/Fetch";
import AppHeading from "./sharedComponents/AppHeading";

import Error from "./Error";

class ChooseDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { departments: [], isError: false, errorMessage: undefined };
  }

  componentDidMount() {
    this.fetchDepartments();
  }

  render() {
    const { departments, isError, errorMessage } = this.state;

    if (isError) {
      return (
        <Error text="Fikk ikke hentet avdelinger">
          <p>Dette kan være for at tjeneren ikke kjører ({errorMessage}).</p>
          <a href="/">Prøv på nytt</a>
        </Error>
      );
    }

    let department = this.props.department;
    if (!department && departments.length > 0) {
      department = departments[0].id;
    }

    return (
      <div className="module">
        <AppHeading department={department} hideItems={true} />
        <div style={{ textAlign: "center" }} className="departments">
          <div>
            <h2>Velg avdeling</h2>
            <div style={{ marginTop: "30px" }}>
              {departments.map((dep, i) => (
                <h3 key={i} style={{ marginTop: "20px" }}>
                  <Link name={dep.id} to={"/" + dep.id + "/consultants/8/0"}>
                    {dep.name}
                  </Link>
                </h3>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  fetchDepartments() {
    fetch("/departments")
      .then(departments => {
        this.setState({ departments, isError: false });
      })
      .catch(err => {
        this.setState({ errorMessage: err.message, isError: true });
        console.error("Something went wrong while fetching departments", err);
      });
  }
}

export default ChooseDepartment;
