import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import Table from "./sharedComponents/Table";
import { Heading } from "./sharedComponents/Atoms";

const AvailableOnly = styled.input``;

const CheckboxLabel = styled.span`
  margin-left: 5px;

  @media (max-width: 750px) {
    display: none;
  }
`;

const ShortenedCheckboxLabel = styled.span`
  display: none;
  @media (max-width: 750px) {
    margin-left: 5px;
    display: inline-block;
  }
`;

const StyledConsultantRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
  width: 40%;

  @media (max-width: 900px) {
    width: 30%;
  }

  @media (max-width: 750px) {
    padding: 0;
    font-size: 0.8rem;
  }
`;

const WeekData = styled.td`
  white-space: nowrap;
  text-align: center;

  background-color: ${props => (props.available ? "#ffc4bc" : "")};
  background-color: ${props => (props.overBooked ? "#ff0166" : "")};

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const OfferData = styled.span`
  font-size: 0.8rem;
  color: #6200ee;

  @media (max-width: 900px) {
    display: none;
  }
`;

class Consultants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      yearWeeks: [],
      orderedWeekSum: [],
      offeredWeekSum: [],
      waitingForData: true,
      hideFullyBooked: false,
      department: this.props.match.params.department
    };
    this.toggleFullyBooked = this.toggleFullyBooked.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  toggleFullyBooked(event) {
    this.setState({ hideFullyBooked: event.target.checked }, this.fetchData);
  }

  render() {
    const { consultants, yearWeeks, orderedWeekSum, offeredWeekSum, department } = this.state;

    let offset = this.props.match.params.offset ? this.props.match.params.offset : 0;
    let weeks = this.props.match.params.weeks ? this.props.match.params.weeks : 8;
    return (
      <div className="module">
        <AppHeading department={department} offset={offset} weeks={weeks}>
          {" "}
        </AppHeading>
        <div className="content consultants">
          <div className="consultants__heading">
            <Heading>Konsulenter</Heading>
            <div>
              <AvailableOnly
                name="showOnlyAvailableConsultants"
                checked={this.state.hideFullyBooked}
                onChange={this.toggleFullyBooked}
                type="checkbox"
              />
              <CheckboxLabel>Kun konsulenter med ledig tid</CheckboxLabel>
              <ShortenedCheckboxLabel>Kun ledig tid</ShortenedCheckboxLabel>
            </div>
          </div>
          <div className="consultants__container">
            <Spinner show={this.state.waitingForData} />
            <Table leadingHeaders={["Navn"]} yearWeeks={yearWeeks}>
              {consultants.map((consultant, i) => (
                <ConsultantRow
                  key={i}
                  consultant={consultant}
                  department={department}
                  weeks={weeks}
                  offset={offset}
                />
              ))}
              <tr>
                <ConsultantName>SUM ordrereserve</ConsultantName>
                <td />
                {orderedWeekSum.map((sum, i) => (
                  <WeekData key={i}>{format(sum)}</WeekData>
                ))}
                <td />
              </tr>
              <tr>
                <ConsultantName>SUM tilbud- og ordrereserve</ConsultantName>
                <td />
                {offeredWeekSum.map((sum, i) => (
                  <WeekData key={i}>{format(sum)}</WeekData>
                ))}
                <td />
              </tr>
            </Table>
          </div>
        </div>
      </div>
    );
  }

  getWeekSum(consultants, properties, weeks) {
    return consultants
      .map(consultant => consultant.staffing)
      .reduce(
        (result, currentValue) => {
          for (var i = 0; currentValue.length > i; i++) {
            for (var j = 0; j < properties.length; j++) {
              if (isNaN(result[i])) result[i] = 0;
              result[i] += parseFloat(currentValue[i][properties[j]], 10);
            }
          }
          return result;
        },
        [0]
      );
  }

  fetchData() {
    this.setState({ waitingForData: true });
    const { department, hideFullyBooked } = this.state;
    let offset = this.props.match.params.offset;
    if (!offset) offset = 0;

    let weeks = this.props.match.params.weeks;
    if (!weeks) weeks = 8;
    fetch(
      `/consultants/offset/${offset}/weeks/${weeks}?department=${department}` +
        (hideFullyBooked ? "&hideFullyBooked=1" : "")
    )
      .then(response => {
        const yearWeeks =
          response.length > 0 ? response[0].staffing.map(week => week.yearWeek) : [];

        const offeredWeekSum = this.getWeekSum(
          response,
          ["offeredHours", "orderedHours"],
          this.props.match.params.weeks
        );
        const orderedWeekSum = this.getWeekSum(
          response,
          ["orderedHours"],
          this.props.match.params.weeks
        );

        this.setState({
          consultants: response,
          yearWeeks: yearWeeks,
          offeredWeekSum: offeredWeekSum,
          orderedWeekSum: orderedWeekSum,
          waitingForData: false
        });
      })
      .catch(reason => {
        console.log("Reqeust resulted in an error", reason);
      });
  }
}

const ConsultantRow = ({ consultant, department, weeks, offset }) => (
  <StyledConsultantRow>
    <ConsultantName>
      <Link
        to={
          "/" + department + "/consultant/" + consultant.consultantId + "/" + weeks + "/" + offset
        }
      >
        {consultant.name}
      </Link>
    </ConsultantName>
    <td />
    {consultant.staffing.map((week, i) => (
      <WeekData
        key={i}
        available={week.bookedHours < parseFloat(consultant.fullWeekHours, 0)}
        overBooked={week.bookedHours > parseFloat(consultant.fullWeekHours, 0)}
      >
        {format(week.orderedHours)} <OfferData>{format(week.offeredHours)}</OfferData>{" "}
      </WeekData>
    ))}
    <td />
  </StyledConsultantRow>
);

export default Consultants;
