import { API_URL } from "./Settings";
import { removeToken, getToken } from "./Auth";

let unauthedCallback = function() {};

export default function request(uri, opt) {
  const headers = new Headers();
  const token = getToken();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");
  if (token) {
    headers.set("Authorization", "Bearer " + token);
  }

  const options = {
    headers,
    mode: "cors",
    ...opt
  };
  return fetch(appendUrl(API_URL + uri), options)
    .then(i => {
      if (i.status === 401) {
        removeToken();
        unauthedCallback();
      }
      return i;
    })
    .then(i => i.json());
}

export function set401Action(fn) {
  unauthedCallback = fn;
}

function appendUrl(url, token) {
  if (!token) return url;

  const separator = url.includes("?") ? "&" : "?";
  return url + separator + "_token=" + token;
}
