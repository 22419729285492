import React from "react";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import { getCurrentYear } from "./shared/TimeUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import { Heading } from "./sharedComponents/Atoms";

const BudgetTable = styled.table`
  width: 100%;
`;

const StyledBudgetRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const StyledBudgetData = styled.td`
  white-space: nowrap;
  text-align: center;
  padding: 0.5rem;
`;

const NewBudgetYearButton = styled.button`
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
`;

const BudgetDataInput = styled.input`
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  background: transparent;
  border: 0;
`;

class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: [],
      department: this.props.match.params.department
    };
    this.addNewBudgetYear = this.addNewBudgetYear.bind(this);
  }

  componentDidMount() {
    this.fetchBudget();
  }

  render() {
    const { budget, department } = this.state;
    return (
      <div className="module">
        <AppHeading
          department={this.props.match.params.department}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
        >
          {" "}
        </AppHeading>

        <form className="budget content">
          <Heading>Budsjett</Heading>
          <Spinner show={this.state.waitingForData} />
          <BudgetTable>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>ÅR</th>
                <th>Juli</th>
                <th>August</th>
                <th>September</th>
                <th>Oktober</th>
                <th>November</th>
                <th>Desember</th>
                <th>Januar</th>
                <th>Februar</th>
                <th>Mars</th>
                <th>April</th>
                <th>Mai</th>
                <th>Juni</th>
              </tr>
            </thead>
            <tbody>
              {budget.map((b, i) => (
                <BudgetRow key={i} budgetYear={b} department={department} />
              ))}
            </tbody>
          </BudgetTable>

          <NewBudgetYearButton
            style={{
              margin: "10px 0 0 0"
            }}
            type="submit"
            onClick={this.addNewBudgetYear}
          >
            Legg til nytt budsjettår
          </NewBudgetYearButton>
        </form>
      </div>
    );
  }

  addNewBudgetYear(e) {
    e.preventDefault();

    const newYear =
      this.state.budget.length > 0
        ? Math.max.apply(Math, this.state.budget.map(b => b.year)) + 1
        : getCurrentYear();
    fetch("/budget/", {
      method: "POST",
      body: JSON.stringify({
        departmentId: this.state.department,
        yearMonth: newYear + "07",
        value: 0
      })
    }).then(
      response => {
        this.fetchBudget();
      },
      reason => console.log("Something went wrong during the request", reason)
    );
  }

  fetchBudget() {
    this.setState({ waitingForData: true });
    fetch("/budget?department=" + this.state.department)
      .catch(reason => {
        console.log("Someting went wrong during the request", reason);
      })
      .then(response => {
        this.setState({ budget: response, waitingForData: false });
      });
  }
}

class BudgetRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetYear: this.props.budgetYear,
      department: this.props.department
    };
  }

  render() {
    const { budgetYear } = this.state;
    return (
      <StyledBudgetRow>
        <td style={{ fontWeight: "bold", fontSize: "0.7rem" }}>{budgetYear.year}</td>
        {budgetYear.months.map((month, i) => (
          <BudgetData key={i} budgetMonth={month} department={this.state.department} />
        ))}
      </StyledBudgetRow>
    );
  }
}

class BudgetData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetMonth: this.props.budgetMonth,
      department: this.props.department,
      valueChanged: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveBudgetEntry = this.saveBudgetEntry.bind(this);
  }

  handleInputChange(e) {
    const budgetMonth = this.state.budgetMonth;
    budgetMonth.value = e.target.value;
    this.setState({ budgetMonth: budgetMonth, valueChanged: true });
  }

  render() {
    const { budgetMonth } = this.state;
    return (
      <StyledBudgetData style={{ textAlign: "center" }}>
        <BudgetDataInput
          value={budgetMonth.value}
          onChange={this.handleInputChange}
          onBlur={this.saveBudgetEntry}
        />
      </StyledBudgetData>
    );
  }

  saveBudgetEntry() {
    if (this.state.valueChanged) {
      console.log(this.state.budgetMonth);
      fetch("/budget/", {
        method: "POST",
        body: JSON.stringify({
          departmentId: this.state.department,
          yearMonth: this.state.budgetMonth.yearMonth,
          value: this.state.budgetMonth.value
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    }
  }
}

export default Budget;
