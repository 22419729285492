import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { createSelectable } from "react-selectable";

const StyledStaffingRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const LeadingValue = styled.td`
  padding: 0.5rem;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    width: 20%;
  }

  @media (max-width: 750px) {
    padding: 0;
    a {
      font-size: 0.7rem;
    }
  }
`;

const WeekDataStyled = styled.td`
  white-space: nowrap;
  text-align: center;

  @media (max-width: 750px) {
    input {
      font-size: 0.7rem;
    }
  }
`;

class WeekData extends React.Component {
  render() {
    return (
      <WeekDataStyled>
        <input
          type="number"
          onBlur={this.props.onBlur}
          data-yearweek={this.props.yearweek}
          value={this.props.value}
          name={this.props.yearWeek}
          autoFocus={this.props.setFocus}
          onChange={this.props.onChange}
          className="weekData__input"
          max="99"
        />
      </WeekDataStyled>
    );
  }
}

const SelectableWeekData = createSelectable(WeekData);

class StaffingRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueUpdated: false,
      department: this.props.department,
      data: this.props.data
    };
    this.updateStaffing = this.updateStaffing.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  updateStaffing(e) {
    if (this.state.valueUpdated) {
      this.props.sumbitStaffing(
        e.target.value,
        this.props.consultantId,
        this.props.engagementId,
        e.target.dataset.yearweek
      );
      this.setState({ valueUpdated: false });
      this.props.updateWeekSum();
    }
  }

  updateInputValue(index, e) {
    const data = this.state.data;
    data.staffing[index].hours = e.target.value;
    this.setState({ data: data, valueUpdated: true });
  }

  render() {
    const { department } = this.state;

    return (
      <StyledStaffingRow>
        {this.props.leadingValues.map((leadingValue, i) => (
          <LeadingValue key={i}>
            <Link
              to={
                "/" +
                department +
                "/" +
                leadingValue.link +
                "/" +
                this.props.weeks +
                "/" +
                this.props.offset
              }
            >
              {leadingValue.value}
            </Link>
          </LeadingValue>
        ))}
        <td />
        {this.state.data.staffing.map((week, i) => (
          <SelectableWeekData
            key={i}
            selectableKey={{
              engagementIndex: this.props.engagementIndex,
              consultantIndex: this.props.consultantIndex,
              weekIndex: i
            }}
            onBlur={this.updateStaffing}
            yearweek={week.yearWeek}
            value={week.hours}
            setFocus={this.props.setFocus && i === 0}
            onChange={this.updateInputValue.bind(this, i)}
          />
        ))}
        <td
          className={
            this.props.engagementStatus.toLowerCase() === "tilbud"
              ? "engagementRow__offerRow"
              : null
          }
          title={this.props.engagementStatus.toLowerCase() === "tilbud" ? "Tilbud" : "Ordre"}
        />
      </StyledStaffingRow>
    );
  }
}

export default StaffingRow;
