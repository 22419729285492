import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Table from "./sharedComponents/Table";
import StaffingRow from "./sharedComponents/StaffingRow";
import { getOffsetYearWeek } from "./shared/TimeUtil";
import Autocomplete from "react-autocomplete";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import { SelectableGroup } from "react-selectable";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";

const SubHeading = styled.h2`
  padding: 0 0 1rem 0;
  font-size: 1rem;
  display: inline;
  margin-left: 7px;
`;

const StyledNewConsultantRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
  width: 40%;
`;

const WeekData = styled.td`
  white-space: nowrap;
  text-align: center;
`;

const AddConsultantButton = styled.button`
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
`;

const StatusIndicator = styled.div`
  display: ${props => (props.offer ? "inline-block" : "none")};
  height: 100%;
  width: 5px;
  background: var(--purple);
  position: absolute;
  top: 0;
  left: 0;
`;

const EngagementName = styled.input`
  background: inherit;
  border: 0;
  font-size: 1.5rem;
  width: 100%;
  outline: none;
`;

const EngagementMetaDataInput = styled.input`
  font-size: 16px;
  border: 0;
  outline: none;
  font-family: sans-serif;
  color: var(--black);
`;

const MetaDataContainer = styled.div`
  margin-top: 10px;
`;

const HiddenSelect = styled.select`
  background: transparent;
  border: 0px;
  outline: none;
  font-size: 16px;
  color: var(--black);
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddConsultantRow: false,
      engagement: {
        consultants: [],
        engagementStatus: "",
        engagementName: "",
        engagementPartner: "",
        engagementProjectManager: ""
      },
      yearWeeks: [],
      engagementWeekSum: [],
      newConsultantId: "",
      focusConsultantId: "",
      valueChanged: false,
      waitingForData: true,
      department: this.props.match.params.department
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleAddConsultant = this.toggleAddConsultant.bind(this);
    this.updateWeekSum = this.updateWeekSum.bind(this);
    this.selectNewConsultant = this.selectNewConsultant.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveConsultantToProject = this.saveConsultantToProject.bind(this);
    this.saveEngagement = this.saveEngagement.bind(this);
    this.handleDragSelection = this.handleDragSelection.bind(this);
    this.setStaffing = this.setStaffing.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  toggleAddConsultant() {
    this.setState({ showAddConsultantRow: !this.state.showAddConsultantRow });
  }

  updateWeekSum() {
    this.setState({
      engagementWeekSum: this.getWeekSum(this.state.engagement.consultants)
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  selectNewConsultant(newConsultantId) {
    this.setState({
      newConsultantId: newConsultantId
    });
  }

  handleInputChange(e) {
    const engagement = this.state.engagement;
    const property = e.target.name;
    if (engagement[property] !== e.target.value) {
      engagement[property] = e.target.value;
      if (e.target.type === "select-one") {
        this.setState({ engagement: engagement, valueChanged: true }, this.saveEngagement);
      } else {
        this.setState({ engagement: engagement, valueChanged: true });
      }
    }
  }

  render() {
    const { yearWeeks, engagement, engagementWeekSum, department } = this.state;
    let weeks = this.props.match.params.weeks;
    let offset = this.props.match.params.offset;

    return (
      <div className="module">
        <AppHeading department={department} offset={offset} weeks={weeks} />
        <div className="project content">
          <StatusIndicator offer={engagement.engagementStatus.toLowerCase() === "tilbud"} />
          <EngagementName
            onChange={this.handleInputChange}
            onBlur={this.saveEngagement}
            name="engagementName"
            value={engagement.engagementName}
          />
          for
          <SubHeading>
            <Link
              to={
                "/" + department + "/customer/" + engagement.customerId + "/" + weeks + "/" + offset
              }
            >
              {engagement.customerName}
            </Link>
          </SubHeading>
          <div>
            <MetaDataContainer>
              <label>Ansvarlig partner: </label>
              <EngagementMetaDataInput
                onChange={this.handleInputChange}
                onBlur={this.saveEngagement}
                name="engagementPartner"
                value={engagement.engagementPartner}
              />
            </MetaDataContainer>
            <MetaDataContainer>
              <label>Prosjektleder: </label>
              <EngagementMetaDataInput
                onChange={this.handleInputChange}
                onBlur={this.saveEngagement}
                name="engagementProjectManager"
                value={engagement.engagementProjectManager}
              />
            </MetaDataContainer>
            <MetaDataContainer>
              <label>Status: </label>
              <HiddenSelect
                className="testSelect"
                onChange={this.handleInputChange}
                onBlur={this.saveEngagement}
                value={engagement.engagementStatus}
                name="engagementStatus"
              >
                <option>Tilbud</option>
                <option>Tapt</option>
                <option defaultValue>Ordre</option>
                <option>Avsluttet</option>
                <option>Internt</option>
              </HiddenSelect>
            </MetaDataContainer>
          </div>
          <div className="consultant__container">
            <Spinner show={this.state.waitingForData} />
            <SelectableGroup preventDefault={false} onSelection={this.handleDragSelection}>
              <Table leadingHeaders={["Konsulenter"]} yearWeeks={yearWeeks}>
                {engagement.consultants.map((consultant, i) => (
                  <StaffingRow
                    key={i}
                    consultantIndex={i}
                    leadingValues={[
                      {
                        link: "consultant/" + consultant.consultantId,
                        value: consultant.consultantName
                      }
                    ]}
                    engagementId={engagement.engagementId}
                    data={consultant}
                    consultantId={consultant.consultantId}
                    updateWeekSum={this.updateWeekSum}
                    engagementStatus={engagement.engagementStatus}
                    department={department}
                    weeks={weeks}
                    offset={offset}
                    sumbitStaffing={this.sumbitStaffing}
                    setFocus={this.state.focusConsultantId === consultant.consultantId}
                  />
                ))}
                {!this.state.showAddConsultantRow ? null : (
                  <AddConsultantRow
                    engagement={engagement}
                    department={department}
                    newConsultantSelected={this.saveConsultantToProject}
                    weeks={weeks}
                  />
                )}
                <tr>
                  <ConsultantName>SUM</ConsultantName>
                  <td />
                  {engagementWeekSum.map((sum, i) => (
                    <WeekData key={i}>{format(sum)}</WeekData>
                  ))}
                  <td />
                </tr>
              </Table>
            </SelectableGroup>

            <AddConsultantButton
              style={{
                display: !this.state.showAddConsultantRow ? "inline-block" : "none"
              }}
              onClick={this.toggleAddConsultant}
            >
              Legg til konsulent
            </AddConsultantButton>
            <AddConsultantButton
              style={{
                display: this.state.showAddConsultantRow ? "inline-block" : "none",
                marginLeft: "10px"
              }}
              onClick={this.toggleAddConsultant}
            >
              Avbryt
            </AddConsultantButton>
          </div>
        </div>
      </div>
    );
  }

  getWeekSum(consultants) {
    return consultants
      .map(consultant => consultant.staffing)
      .reduce((result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          result[i] += parseFloat(currentValue[i].hours, 10);
        }
        return result;
      }, Array(consultants[0].staffing.length).fill(0));
  }

  sumbitStaffing(hours, consultantId, engagementId, yearWeek) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        hours: hours,
        consultantId: consultantId,
        engagementId: engagementId,
        yearWeek: yearWeek
      })
    });
  }

  setStaffing(hours, consultantIndex, weekIndex) {
    let engagement = this.state.engagement;
    let consultantIdToYpdate = engagement.consultants[consultantIndex].consultantId;
    let yearWeekToUpdate = engagement.consultants[consultantIndex].staffing[weekIndex].yearWeek;
    engagement.consultants[consultantIndex].staffing[weekIndex].hours = hours;
    this.setState({ engagement: engagement });
    this.sumbitStaffing(
      hours,
      consultantIdToYpdate,
      this.state.engagement.engagementId,
      yearWeekToUpdate
    );
  }

  handleDragSelection(selectedKeys) {
    if (!selectedKeys) return;

    let firstConsultantIndex = selectedKeys[0].consultantIndex;
    let firstWeekIndex = selectedKeys[0].weekIndex;
    let hours = this.state.engagement.consultants[firstConsultantIndex].staffing[firstWeekIndex]
      .hours;

    for (let i = 1; i < selectedKeys.length; i++) {
      this.setStaffing(hours, selectedKeys[i].consultantIndex, selectedKeys[i].weekIndex);
    }
    this.updateWeekSum();
  }

  fetchData() {
    this.setState({ waitingForData: true });
    const id = this.props.match.params.id;

    fetch(
      `/engagements/${id}/offset/${this.props.match.params.offset}/weeks/${
        this.props.match.params.weeks
      }`
    )
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        const yearWeeks = response.emptyStaffing.map(week => week.yearWeek);
        const engagementWeekSum = this.getWeekSum(response.consultants);
        this.setState({
          engagement: response,
          yearWeeks: yearWeeks,
          engagementWeekSum: engagementWeekSum,
          waitingForData: false
        });
      });
  }

  saveEngagement() {
    if (this.state.valueChanged) {
      fetch("/engagements/" + this.state.engagement.engagementId, {
        method: "PUT",
        body: JSON.stringify({
          name: this.state.engagement.engagementName,
          status: this.state.engagement.engagementStatus,
          partner: this.state.engagement.engagementPartner,
          projectManager: this.state.engagement.engagementProjectManager
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    }
  }

  saveConsultantToProject(newConsultantId) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        consultantId: newConsultantId,
        engagementId: this.state.engagement.engagementId,
        yearWeek: getOffsetYearWeek(this.props.match.params.offset),
        hours: 0
      })
    }).then(
      response => {
        this.setState(
          { focusConsultantId: newConsultantId, showAddConsultantRow: false },
          this.fetchData()
        );
      },
      reason => {
        console.log("Something went wrong while performing the request", reason);
      }
    );
  }
}

class AddConsultantRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchConsultantName: "",
      newConsultant: {},
      consultants: [],
      engagement: this.props.engagement,
      department: this.props.department
    };
  }

  componentDidMount() {
    this.fetchConsultants();
  }

  purgeConsultants(consultants) {
    return consultants.filter(consultant => {
      return !this.state.engagement.consultants.find(
        existingConsultant => existingConsultant.consultantId === consultant.consultantId
      );
    });
  }

  render() {
    const { consultants } = this.state;
    return (
      <StyledNewConsultantRow>
        <ConsultantName>
          <Autocomplete
            inputProps={{
              placeholder: "Konsulent",
              className: "new-project-input"
            }}
            items={consultants}
            shouldItemRender={(item, value) =>
              item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.name}
            selectOnBlur={true}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.consultantId}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "0.5rem"
                }}
              >
                {item.name}
              </div>
            )}
            value={this.state.searchConsultantName}
            onChange={e => this.setState({ searchConsultantName: e.target.value })}
            onSelect={(value, item) => {
              this.setState({ searchConsultantName: value });
              this.props.newConsultantSelected(item.consultantId);
            }}
          />
        </ConsultantName>
        <td />
        <td colSpan={this.props.weeks} />
        <td />
      </StyledNewConsultantRow>
    );
  }

  fetchConsultants() {
    fetch("/consultants?department=" + this.state.department)
      .catch(reason => {
        console.log("Reqeust resulted in an error", reason);
      })
      .then(response => {
        const consultants = this.purgeConsultants(response);
        this.setState({
          consultants: consultants
        });
      });
  }
}

export default Project;
