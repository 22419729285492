import React from "react";
import styled from "styled-components";
import { SelectableGroup } from "react-selectable";
import Autocomplete from "react-autocomplete";
import { getOffsetYearWeek } from "./shared/TimeUtil";
import StaffingRow from "./sharedComponents/StaffingRow";
import Table from "./sharedComponents/Table";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";

const Heading = styled.h3`
  padding: 0 0 1rem 0;

  @media (max-width: 750px) {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const StyledNewProjectRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const ProjectName = styled.td`
  padding: 0.5rem;
  width: 20%;

  @media (max-width: 750px) {
    padding: 0;
    input {
      font-size: 0.7rem;
      width: 100%;
    }
  }
`;

const CustomerName = styled.td`
  padding: 0.5rem;
  width: 20%;

  @media (max-width: 750px) {
    padding: 0;
    input {
      font-size: 0.7rem;
      width: 100%;
    }
  }
`;

const WeekData = styled.td`
  white-space: nowrap;
  text-align: center;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
  padding: 10px 5px 10px 5px;

  @media (max-width: 750px) {
    font-weight: normal;
    font-size: 0.7rem;
  }
`;

const SumLeadingColumn = styled.td`
  padding: 10px 5px 10px 5px;
  @media (max-width: 750px) {
    padding: 5px 0 5px 0;
    font-size: 0.6rem;
  }
`;

const NewProjectButton = styled.button`
  margin-top: 10px;
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
`;

class Consultant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewProjectRow: false,
      consultant: { engagements: [] },
      customers: [],
      yearWeeks: [],
      orderedWeekSum: [],
      offeredWeekSum: [],
      focusEngagementId: "",
      waitingForData: true,
      department: this.props.match.params.department
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleNewProject = this.toggleNewProject.bind(this);
    this.saveNewProject = this.saveNewProject.bind(this);
    this.fetchConsultants = this.fetchConsultants.bind(this);
    this.updateWeekSum = this.updateWeekSum.bind(this);
    this.handleDragSelection = this.handleDragSelection.bind(this);
    this.sumbitStaffing = this.sumbitStaffing.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  setStaffing(hours, engagementIndex, weekIndex) {
    let consultant = this.state.consultant;
    let engagementIdToUpdate = consultant.engagements[engagementIndex].engagementId;
    let yearWeekToUpdate = consultant.engagements[engagementIndex].staffing[weekIndex].yearWeek;

    consultant.engagements[engagementIndex].staffing[weekIndex].hours = hours;
    this.setState({ consultant: consultant });
    this.sumbitStaffing(
      hours,
      this.state.consultant.consultantId,
      engagementIdToUpdate,
      yearWeekToUpdate
    );
  }

  handleDragSelection(selectedKeys) {
    if (!selectedKeys || !selectedKeys[0]) return;

    let firstEngagementIndex = selectedKeys[0].engagementIndex;
    let firstWeekIndex = selectedKeys[0].weekIndex;
    let hours = this.state.consultant.engagements[firstEngagementIndex].staffing[firstWeekIndex]
      .hours;

    for (let i = 1; i < selectedKeys.length; i++) {
      this.setStaffing(hours, selectedKeys[i].engagementIndex, selectedKeys[i].weekIndex);
    }
    this.updateWeekSum();
  }

  sumbitStaffing(hours, consultantId, engagementId, yearWeek) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        hours: hours,
        consultantId: consultantId,
        engagementId: engagementId,
        yearWeek: yearWeek
      })
    });
  }

  render() {
    const { consultant, yearWeeks, orderedWeekSum, offeredWeekSum, department } = this.state;
    return (
      <div className="module">
        <AppHeading
          department={department}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
        >
          {" "}
        </AppHeading>

        <div className="consultant content">
          <Heading>{consultant.consultantName}</Heading>
          {
            <form className="consultant__container">
              <Spinner show={this.state.waitingForData} />
              <SelectableGroup preventDefault={false} onSelection={this.handleDragSelection}>
                <Table leadingHeaders={["Kunde", "Prosjekt"]} yearWeeks={yearWeeks}>
                  {consultant.engagements.map((engagement, i) => (
                    <StaffingRow
                      key={i}
                      engagementIndex={i}
                      leadingValues={[
                        {
                          link: "customer/" + engagement.customerId,
                          value: engagement.customerName
                        },
                        {
                          link: "project/" + engagement.engagementId,
                          value: engagement.engagementName
                        }
                      ]}
                      engagementStatus={engagement.engagementStatus}
                      data={engagement}
                      consultantId={consultant.consultantId}
                      engagementId={engagement.engagementId}
                      updateWeekSum={this.updateWeekSum}
                      department={department}
                      weeks={this.props.match.params.weeks}
                      offset={this.props.match.params.offset}
                      sumbitStaffing={this.sumbitStaffing}
                      setFocus={this.state.focusEngagementId === engagement.engagementId}
                    />
                  ))}
                  {!this.state.showNewProjectRow ? null : (
                    <NewProjectRow
                      consultant={consultant}
                      customers={this.state.customers}
                      newProjectSelected={this.saveNewProject}
                      weeks={this.props.match.params.weeks}
                    />
                  )}
                  <SumRow style={{ borderTop: "1px solid var(--black)" }}>
                    <SumLeadingColumn>SUM ordrereserve</SumLeadingColumn>
                    <td />
                    <td />
                    {orderedWeekSum.map((sum, i) => (
                      <WeekData key={i}>{format(sum)}</WeekData>
                    ))}
                    <td />
                  </SumRow>
                  <SumRow>
                    <SumLeadingColumn>SUM tilbud- og ordrereserve</SumLeadingColumn>
                    <td />
                    <td />
                    {offeredWeekSum.map((sum, i) => (
                      <WeekData key={i}>{format(sum)}</WeekData>
                    ))}
                    <td />
                  </SumRow>
                </Table>
              </SelectableGroup>
            </form>
          }
          <NewProjectButton
            style={{
              display: !this.state.showNewProjectRow ? "inline-block" : "none"
            }}
            type="button"
            onClick={this.toggleNewProject}
          >
            Legg til prosjekt
          </NewProjectButton>
          <NewProjectButton
            style={{
              display: this.state.showNewProjectRow ? "inline-block" : "none",
              marginLeft: "10px"
            }}
            type="button"
            onClick={this.toggleNewProject}
          >
            Avbryt
          </NewProjectButton>
        </div>
      </div>
    );
  }

  updateWeekSum() {
    this.setState({
      orderedWeekSum: this.getWeekSum(this.state.consultant.engagements, true),
      offeredWeekSum: this.getWeekSum(this.state.consultant.engagements, false)
    });
  }

  getWeekSum(engagements, isOrdered = true) {
    let numWeeks = parseInt(this.props.match.params.weeks, 10);
    if (engagements.length === 0) return Array(numWeeks).fill(0);
    return engagements
      .filter(
        engagement =>
          (isOrdered &&
            (engagement.engagementStatus.toLowerCase() === "ordre" ||
              engagement.engagementStatus.toLowerCase() === "ferdig")) ||
          ((!isOrdered && engagement.engagementStatus.toLowerCase() === "tilbud") ||
            engagement.engagementStatus.toLowerCase() === "ordre" ||
            engagement.engagementStatus.toLowerCase() === "ferdig")
      )
      .map(engagement => engagement.staffing)
      .reduce((result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          result[i] += parseFloat(currentValue[i].hours, 10);
        }
        return result;
      }, Array(engagements[0].staffing.length).fill(0));
  }

  saveNewProject(newEngagementId) {
    console.log(getOffsetYearWeek(this.props.match.params.offset));

    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        consultantId: this.state.consultant.consultantId,
        engagementId: newEngagementId,
        yearWeek: getOffsetYearWeek(this.props.match.params.offset),
        hours: 0
      })
    }).then(
      response => {
        this.setState(
          { showNewProjectRow: false, focusEngagementId: newEngagementId },
          this.fetchConsultants()
        );
      },
      reason => {
        console.log("Something went wrong while performing the request", reason);
      }
    );
  }

  toggleNewProject(e) {
    e.preventDefault();
    this.setState({ showNewProjectRow: !this.state.showNewProjectRow });
  }

  fetchData() {
    this.setState({ waitingForData: true });
    this.fetchConsultants();
    if (this.state.customers.length === 0) {
      this.fetchCustomers();
    }
  }

  fetchConsultants() {
    const id = this.props.match.params.id;
    const { department } = this.state;

    fetch(
      `/consultants/${id}/offset/${this.props.match.params.offset}/weeks/${
        this.props.match.params.weeks
      }?department=${department}`
    )
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        const yearWeeks = response.emptyStaffing.map(week => week.yearWeek);
        const orderedWeekSum = this.getWeekSum(response.engagements, true);
        const offeredWeekSum = this.getWeekSum(response.engagements, false);
        this.setState({
          consultant: response,
          yearWeeks: yearWeeks,
          orderedWeekSum: orderedWeekSum,
          offeredWeekSum: offeredWeekSum,
          waitingForData: false
        });
      });
  }

  fetchCustomers() {
    fetch("/customers?department=" + this.state.department)
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        this.setState({ customers: response });
      });
  }
}

class NewProjectRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCustomerName: "",
      searchEngagementName: "",
      newCustomer: {},
      engagements: [],
      customers: this.purgeProjects()
    };
  }

  purgeProjects() {
    const customers = this.props.customers.slice().map(customer => {
      customer.engagements = customer.engagements.filter(customerEngagement => {
        let keepEngagement = true;
        this.props.consultant.engagements.forEach(consultantEngagement => {
          if (consultantEngagement.engagementId === customerEngagement.engagementId) {
            keepEngagement = false;
          }
        });
        return keepEngagement;
      });
      return customer;
    });
    return customers.filter(customers => customers.engagements.length > 0);
  }

  render() {
    const { customers } = this.state;
    return (
      <StyledNewProjectRow>
        <CustomerName>
          <Autocomplete
            inputProps={{
              placeholder: "Kunde",
              className: "new-project-input"
            }}
            items={customers}
            shouldItemRender={(item, value) =>
              item.customerName.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.customerName}
            selectOnBlur={true}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.customerId}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "0.5rem"
                }}
              >
                {item.customerName}
              </div>
            )}
            value={this.state.searchCustomerName}
            onChange={e => this.setState({ searchCustomerName: e.target.value })}
            onSelect={(value, item) => {
              this.setState({
                newCustomer: item,
                engagements: item.engagements,
                searchCustomerName: value,
                searchEngagementName: ""
              });
            }}
          />
        </CustomerName>
        <ProjectName>
          <Autocomplete
            inputProps={{
              placeholder: "Prosjekt",
              className: "new-project-input"
            }}
            items={this.state.engagements}
            shouldItemRender={(item, value) =>
              item.engagementName.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.engagementName}
            selectOnBlur={true}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.engagementId}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "0.5rem"
                }}
              >
                {item.engagementName}
              </div>
            )}
            value={this.state.searchEngagementName}
            onChange={e => this.setState({ searchEngagementName: e.target.value })}
            onSelect={(value, item) => {
              this.setState({ searchEngagementName: value });
              this.props.newProjectSelected(item.engagementId);
            }}
          />
        </ProjectName>
        <td colSpan={2} />

        <td colSpan={this.props.weeks} />
        <td />
      </StyledNewProjectRow>
    );
  }
}

export default Consultant;
