import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { splitYearWeek, getFirstDayOfWeek, getCurrentWeek } from "../shared/TimeUtil.js";

const Navigation = styled.th`
  font-size: 1.5rem;
  cursor: pointer;
  border-bottom: none;

  @media (max-width: 750px) {
    font-size: 1rem;
  }
`;

const LeadingHeading = styled.th`
  text-align: left;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const FirstDate = styled.span`
  font-size: 0.8rem;

  @media (max-width: 900px) {
    display: none;
  }
`;

const ConsultantDataHeading = styled.th`
  @media (max-width: 750px) {
    font-size: 0.5rem;
  }
`;

function getOffsetPath(direction) {
  let pathname = window.location.pathname;
  let params = pathname.split("/");
  let weeks = params[params.length - 2];
  let offset = params[params.length - 1];
  params[params.length - 1] = parseInt(offset, 10) + Math.round((direction * weeks) / 2);
  return params.join("/");
}

export default function TableHeading({ leadingHeaders, yearWeeks }) {
  let tds = [];
  const currentWeek = getCurrentWeek();

  leadingHeaders.forEach(function(leadingHeader, index) {
    tds.push(<LeadingHeading key={index * -1 - 6}>{leadingHeader}</LeadingHeading>);
  });

  tds.push(
    <Navigation key="previous-links">
      <Link to={getOffsetPath(-1)}>
        <span>←</span>
      </Link>
    </Navigation>
  );

  yearWeeks.map((yearWeek, i) => {
    const yearAndWeek = splitYearWeek(yearWeek);
    return tds.push(
      <ConsultantDataHeading key={i}>
        <span className={currentWeek === yearAndWeek.week ? "currentWeek" : null}>
          Uke {yearAndWeek.week}{" "}
        </span>
        <FirstDate>{getFirstDayOfWeek(yearWeek)}</FirstDate>{" "}
      </ConsultantDataHeading>
    );
  });

  tds.push(
    <Navigation key="next-links">
      <Link to={getOffsetPath(1)}>
        <span>→</span>
      </Link>
    </Navigation>
  );

  return (
    <thead>
      <tr>{tds}</tr>
    </thead>
  );
}
