import styled from "styled-components";

export const Heading = styled.h2`
  padding: 0 0 1rem 0;
  font-size: 2rem;

  @media (max-width: 750px) {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;
