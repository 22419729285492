import React from "react";
import { BrowserRouter as HashRouter, Route, Redirect } from "react-router-dom";
import Consultant from "./Consultant";
import Consultants from "./Consultants";
import Customer from "./Customer";
import Customers from "./Customers";
import Project from "./Project";
import Budget from "./Budget";
import Report from "./Report";
import Spinner from "./sharedComponents/Spinner";
import AdministerConsultants from "./AdministerConsultants";
import ChooseDepartment from "./ChooseDepartment";
import { setToken } from "./shared/Auth";
import fetch from "./shared/Fetch";
import { set401Action } from "./shared/Fetch";
import Login from "./Login";
import Error from "./Error";
import "./App.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <Error error={this.state.error} />;
    }

    return this.props.children;
  }
}

function isTokenRequest() {
  return document.location.href.includes("_token");
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      isLoading: true
    };

    this.onLoggedIn = this.onLoggedIn.bind(this);
  }

  componentDidMount() {
    set401Action(() =>
      this.setState({
        isLoggedIn: false,
        isLoading: false
      })
    );
    this.checkLoggedIn();
  }

  onLoggedIn(token) {
    setToken(token);
    document.location.hash = "";
    this.setState({
      shouldRedirect: true,
      isLoading: false
    });
  }

  checkLoggedIn() {
    // If not callback respons from auth server, check if we have
    // valid token. If not, set 401Action is triggered and isLoggedIn will be false
    if (!isTokenRequest()) {
      fetch("/ping").then(response => {
        // Check if is part of oauth flow. If it has location (redirect), don't set as logged in
        if (!response.location) {
          this.setState({ isLoggedIn: true, isLoading: false });
        }
      });
    }
  }

  render() {
    const { isLoggedIn: authed, isLoading } = this.state;
    if (isLoading && !isTokenRequest()) {
      return <Spinner show={true} />;
    }

    let wrap = i => `/:department/${i}/:weeks/:offset`;

    return (
      <HashRouter>
        <div className="App">
          <ErrorBoundary>
            <main className="main">
              <PrivateRoute authed={authed} exact path="/" component={ChooseDepartment} />
              <LoginRoute path="/login" authed={authed} onLoggedIn={this.onLoggedIn} />
              <PrivateRoute authed={authed} path={wrap("consultants")} component={Consultants} />
              <PrivateRoute authed={authed} path={wrap("consultant/:id")} component={Consultant} />
              <PrivateRoute authed={authed} path={wrap("customer/:id")} component={Customer} />
              <PrivateRoute authed={authed} path={wrap("customers")} component={Customers} />
              <PrivateRoute authed={authed} path={wrap("report")} component={Report} />
              <PrivateRoute authed={authed} path={wrap("project/:id")} component={Project} />
              <PrivateRoute authed={authed} path={wrap("budget")} component={Budget} />
              <PrivateRoute
                authed={authed}
                path={wrap("administer")}
                component={AdministerConsultants}
              />
            </main>
          </ErrorBoundary>
        </div>
      </HashRouter>
    );
  }
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        );
      }}
    />
  );
}

function LoginRoute({ authed, ...rest }) {
  const showLogin = !authed || isTokenRequest();
  return (
    <Route
      {...rest}
      render={props => {
        return showLogin ? (
          <Login {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }}
    />
  );
}

export default App;
