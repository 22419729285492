import React from "react";
import fetch from "./shared/Fetch";
import Table from "./sharedComponents/Table";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { format } from "./shared/NumberUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";

const Heading = styled.h2`
  padding: 0 0 1rem 0;
  font-size: 2rem;
`;

const CustomerRow = styled.tr`
  &:nth-child(odd) {
    background: #eee;
  }
`;

const CustomerName = styled.td`
  padding: 0.5rem;
  width: 40%;
`;

const WeekData = styled.td`
  white-space: nowrap;
  text-align: center;
  font-size: 0.8rem;
`;

const OfferData = styled.span`
  font-size: 0.6rem;
  color: #6200ee;
`;

const NewCustomerButton = styled.button`
  margin-top: 10px;
  display: inline-block;
  padding: var(--buttonPadding);
  background: var(--green);
  border-radius: 0.2rem;
  color: var(--black);
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in;
`;

const CustomerMetaDataInput = styled.input`
  font-size: 1rem;
  border: 0;
  outline: none;
  font-family: sans-serif;
  color: var(--black);
  background: #eee;
  text-align: left;
  margin-left: 5px;
  padding: 5px;

  @media (max-width: 750px) {
    width: 100%;
    font-size: 0.7rem;
  }
`;

const HiddenSelect = styled.select`
  margin-left: 5px;
  background: transparent;
  border: 0px;
  outline: none;
  font-size: 1rem;
  color: var(--black);
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      yearWeeks: [],
      department: this.props.match.params.department,
      departments: [],
      newCustomer: {
        name: "",
        departmentId: this.props.match.params.department
      },
      showNewCustomerRow: false,
      waitingForData: true
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleNewCustomer = this.toggleNewCustomer.bind(this);
    this.saveNewCustomer = this.saveNewCustomer.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  handleInputChange(e) {
    const { newCustomer } = this.state;
    const property = e.target.name;
    newCustomer[property] = e.target.value;
    this.setState({ newCustomer: newCustomer });
  }

  toggleNewCustomer() {
    this.setState({
      showNewCustomerRow: !this.state.showNewCustomerRow,
      newCustomer: { name: "", departmentId: this.state.department }
    });
  }

  uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { yearWeeks, customers, department, departments, newCustomer } = this.state;
    return (
      <div className="module">
        <AppHeading
          department={department}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
        >
          {" "}
        </AppHeading>
        <form className="customers content">
          <Heading>Kunder</Heading>
          <Spinner show={this.state.waitingForData} />
          <Table leadingHeaders={["Kunde"]} yearWeeks={yearWeeks}>
            {customers.map((customer, i) => (
              <CustomerRow key={i}>
                <CustomerName>
                  <Link
                    to={
                      "/" +
                      department +
                      "/customer/" +
                      customer.customerId +
                      "/" +
                      this.props.match.params.weeks +
                      "/" +
                      this.props.match.params.offset
                    }
                  >
                    {customer.customerName}
                  </Link>
                </CustomerName>
                <td />
                {customer.staffing.map((staffing, j) => (
                  <WeekData key={j}>
                    {format(staffing.orderedHours)}{" "}
                    <OfferData>{format(staffing.offeredHours)}</OfferData>
                  </WeekData>
                ))}
                <td />
              </CustomerRow>
            ))}
          </Table>
          {this.state.showNewCustomerRow ? (
            <div style={{ padding: "5px" }}>
              <div>
                Kundenavn:
                <CustomerMetaDataInput
                  name="name"
                  onChange={this.handleInputChange}
                  value={newCustomer.name}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                Avdeling:
                <HiddenSelect
                  name="departmentId"
                  onChange={this.handleInputChange}
                  value={this.uppercaseFirstLetter(newCustomer.departmentId)}
                >
                  {departments.map((department, i) => (
                    <option key={i}>{department.name}</option>
                  ))}
                </HiddenSelect>
              </div>
            </div>
          ) : null}
          <NewCustomerButton
            style={{
              display: !this.state.showNewCustomerRow ? "inline-block" : "none"
            }}
            onClick={this.toggleNewCustomer}
            type="button"
          >
            Legg til ny kunde
          </NewCustomerButton>
          <NewCustomerButton
            style={{
              display: this.state.showNewCustomerRow ? "inline-block" : "none"
            }}
            onClick={this.saveNewCustomer}
            type="submit"
          >
            Lagre
          </NewCustomerButton>
          <NewCustomerButton
            style={{
              display: this.state.showNewCustomerRow ? "inline-block" : "none",
              marginLeft: "10px"
            }}
            onClick={this.toggleNewCustomer}
            type="button"
          >
            Avbryt
          </NewCustomerButton>
        </form>
      </div>
    );
  }

  fetchData() {
    this.setState({ waitingForData: true });
    this.fetchCustomers();
    this.fetchDepartments();
  }

  fetchDepartments() {
    fetch("/departments")
      .then(response => {
        this.setState({ departments: response });
      })
      .catch(reason => console.log("Something went wrong while fetching departments"));
  }

  fetchCustomers() {
    const { department } = this.state;
    fetch(
      `/staffings/customers?department=${department}&offset=${
        this.props.match.params.offset
      }&weeks=${this.props.match.params.weeks}`
    )
      .then(response => {
        const yearWeeks =
          response.length > 0 ? response[0].staffing.map(week => week.yearWeek) : [];

        this.setState({ yearWeeks: yearWeeks, customers: response, waitingForData: false });
      })
      .catch(reason => console.log("Someting went wrong while fetching customers"));
  }

  saveNewCustomer(e) {
    e.preventDefault();
    fetch("/customers/", {
      method: "POST",
      body: JSON.stringify({
        name: this.state.newCustomer.name,
        departmentId: this.state.newCustomer.departmentId
      })
    }).then(
      response => {
        this.setState({ showNewCustomerRow: false }, this.fetchCustomers);
      },
      reason => console.log("Something went wrong during the request", reason)
    );
  }
}

export default Customers;
